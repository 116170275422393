import { gql, QueryHookOptions, useQuery, useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { useRouter } from 'next/router';

import {
  BENEFICIAL_OWNER_IDENTIFICATION_PAGE,
  SIGN_IN_PAGE,
  SIGN_UP_PAGE,
  DEMO_SIGN_IN_PAGE,
  REGISTRATION_PAGE,
  IMPERSONATOR_PAGE,
} from 'src/constants/paths';
import { UserHoldingRole, UserInvestmentAccount } from 'src/interfaces/profile-completion';

const userInvestmentAccountQGLString = `
holdingId
holdingRole
id
userId
holdingName
walletDetails {
  walletAddress
  investmentAccountId
  verificationStatus
  creationType
  isWhiteListed
}
`;

export const userInvestmentAccountsQuery = gql`
  query userInvestmentAccounts($userId: Long) {
    userInvestmentAccounts(userId: $userId) {
      ${userInvestmentAccountQGLString}
    }
  }
`;

export const useGetUserInvestmentAccounts = (params?: QueryHookOptions) => {
  const router = useRouter();

  const { data, loading } = useQuery<{ userInvestmentAccounts: UserInvestmentAccount[] }>(
    userInvestmentAccountsQuery,
    {
      ...params,
      fetchPolicy: 'no-cache',
      skip: [
        BENEFICIAL_OWNER_IDENTIFICATION_PAGE,
        SIGN_IN_PAGE,
        SIGN_UP_PAGE,
        DEMO_SIGN_IN_PAGE,
        REGISTRATION_PAGE,
        IMPERSONATOR_PAGE,
      ].includes(router.pathname),
    },
  );

  return {
    userInvestmentAccounts: data?.userInvestmentAccounts || [],
    loading,
  };
};

export const userInvestmentAccountMutation = gql`
  mutation saveUserInvestmentAccount($investmentAccount: UserInvestmentAccountInput) {
    saveUserInvestmentAccount (investmentAccount: $investmentAccount) {
      ${userInvestmentAccountQGLString}
    }
  }
`;

export interface WalletDetailsForApi {
  walletAddress: string;
  investmentAccountId: number;
}

interface UserInvestmentAccountAPI
  extends Omit<UserInvestmentAccount, 'userId' | 'walletDetails' | 'holdingRole'> {
  userId?: number;
  walletDetails?: WalletDetailsForApi;
  holdingRole?: UserHoldingRole;
}

interface UseSaveInvestmentAccount {
  investmentAccount: UserInvestmentAccountAPI;
}

export const useSaveInvestmentAccount = () => {
  const [mutateInvestmentAccount, { loading }] = useMutation(userInvestmentAccountMutation, {
    refetchQueries: [userInvestmentAccountsQuery],
  });

  const saveInvestmentAccount = useCallback(
    async (variables: UseSaveInvestmentAccount) => {
      await mutateInvestmentAccount({ variables });
    },
    [mutateInvestmentAccount],
  );
  return {
    saveInvestmentAccount,
    loading,
  };
};
